.timetableList {
	max-height: calc(100vh - 9rem);
	overflow: auto;
	position: relative;

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: grid;
		row-gap: 1rem;
		li {
			display: grid;
			grid-auto-flow: column;
			justify-content: flex-start;
			align-items: stretch;
			grid-template-columns: min-content 1fr;
			justify-items: stretch;
			.routeName {
				background: var(--green);
				padding: 0.25rem 0.5rem;
				font-weight: bold;
			}
			a {
				text-align: left;
				white-space: nowrap;
				line-height: 2rem;
				padding-left: 1rem;

				display: grid;
				grid-auto-flow: column;
				column-gap: 0.5rem;
				justify-content: flex-start;
				span {
					display: grid;
					align-items: center;
				}
			}
		}
		li:not(.selectedRoute) {
			background: var(--gray);
			a {
				color: var(--black);
			}
		}
		li.selectedRoute {
			background: var(--black);
			a {
				color: var(--white);
				pointer-events: none;
			}
		}
	}
}
